      const SliderData = [
        {
          image:
            'https://images.unsplash.com/photo-1465694930354-a394444f1294?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
        },
        {
          image:
          'https://images.unsplash.com/photo-1529990131237-cfa5ce9517b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80'
        },
        {
          image:
          'https://images.unsplash.com/photo-1608023136037-626dad6c6188?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80'
        },
        {
          image:
          'https://images.freeimages.com/images/large-previews/a5a/airplane-service-1448029.jpg'
        },
        {
          image:
          'https://images.unsplash.com/photo-1615561916422-7014e1078997?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
        },
        {
          image:
          'https://images.unsplash.com/photo-1515593196667-949c6d48700d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1138&q=80'
        },
        {
          image:
          'https://images.unsplash.com/photo-1532364158125-02d75a0f7fb9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80'
        }
      ];

export default SliderData